import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import type {
  FieldNode,
  GraphQLObjectType,
  DocumentNode,
  GraphQLSchema,
  GraphQLField,
} from 'graphql';
import { isObjectType, isListType, getNamedType, getNullableType } from 'graphql';
import { FormattedMessage } from 'react-intl';

import { useDispatch } from '@tmapy/redux';
import { useMessage } from '@tmapy/intl';
import { actionUpdateHashParams, useLink, useLocation } from '@tmapy/router';
import {
  DangerAlert,
  SecondaryLink,
  BtnBar,
  CtaBtn,
  Form,
  SecondaryBtn,
  SuccessAlert,
  WaitingAlert,
} from '@tmapy/style-guide';

import { DataLayoutSpacing } from '../../components/DataLayoutSpacing';
import { GraphQLQueryError, QueryError } from '../../components/QueryError';
import { PageTitle } from '../../components/PageTitle';
import { Tabs } from '../../components/Tabs';
import { Tab } from '../../components/Tab';
import type { DataComponent, DataProps } from '../../types';
import { isConnectionType } from '../../utils';
import { msg } from '../../messages';
import { getDirectives, hiddenFieldsFilter } from '../../utils/getDirectives';
import { getFieldAliasOrName } from '../../utils/getFieldAliasOrName';
import { getFieldsFromFragments } from '../../utils/getFieldsFromFragments';
import { nameComponent } from '../../utils/nameComponent';
import { getOperationNameFromDocument } from '../../utils/getOperationNameFromDocument';
import { filterErrors, ownErrors } from '../../utils/filterErrors';

import { createSelectionSetComponent } from '../createSelectionSetComponent';
import { useMutationAction } from '../useMutationAction';

import { classifyTableFields, createTableComponent } from '../tableComponents/createTableComponent';
import { createMassSelectComponent } from '../selectComponents/createMassSelectComponent';
import type { UseActionDirective } from '../tableComponents/createUseActionDirective';
import { createUseActionDirective } from '../tableComponents/createUseActionDirective';

import { createDetailInputComponent } from './createDetailInputComponent';

const initState = (data: any) => {
  const state = Object.fromEntries(
    Object.entries(data ?? {}).map(([key, originalValue]: [string, any]) => {
      // select ?? input
      let newValue = originalValue?.id ?? originalValue; // TODO: mapovani id na tvrdo?
      // multiselect
      if (originalValue?.edges) {
        newValue = originalValue.edges.map((edge: any) => edge.node?.id);
      }
      return [key, newValue];
    }),
  );
  return data ? state : null;
};

function getFieldFromSelectionSet(field: FieldNode, name: string): FieldNode {
  return field.selectionSet?.selections.find(
    (selection) => (selection as FieldNode).name.value === name,
  ) as FieldNode;
}

export const isMultipleConnectionType = (
  graphqlField: GraphQLField<any, any, any>,
  field: FieldNode,
): boolean => {
  const graphqlType = getNamedType(graphqlField.type) as GraphQLObjectType;
  const edgeType = getNamedType(graphqlType.getFields().edges.type) as GraphQLObjectType;
  const nodeType = getNamedType(edgeType.getFields().node.type) as GraphQLObjectType;
  const columnTypes = nodeType.getFields();

  const edgesField = getFieldFromSelectionSet(field, 'edges');
  const nodeField = getFieldFromSelectionSet(edgesField, 'node');
  const queryColumns = nodeField.selectionSet!.selections!;
  const columns = getFieldsFromFragments(queryColumns);

  const { dataFields } = classifyTableFields(columns, columnTypes);
  return dataFields.length > 1;
};

export function createDetailFormComponent(
  graphqlField: GraphQLField<any, any, any>,
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
): DataComponent {
  const graphqlType = getNullableType(graphqlField.type) as GraphQLObjectType;

  let DetailComponent: DataComponent = () => <>UNKNOWN COMPONENT</>;
  let tabs: { Component: DataComponent; name: string }[] = [];
  let countFields = 0;
  let idName = '';

  if (isObjectType(graphqlType)) {
    const namedType = getNamedType(graphqlType);
    const intlPrefix = namedType.name;

    // TODO: no errors are displayed for hidden attributes
    const fields = field.selectionSet?.selections?.filter(hiddenFieldsFilter) ?? [];
    const graphqlTypeFields = (graphqlType as GraphQLObjectType).getFields();

    const columns = getFieldsFromFragments(fields);
    const { idFields } = classifyTableFields(columns, graphqlTypeFields);
    idName = idFields[0] ? getFieldAliasOrName(idFields[0].fieldNode) : '_id';

    countFields = fields.filter((column) => {
      switch (column.kind) {
        case 'Field': {
          const isTable = isConnectionType(graphqlTypeFields[column.name.value].type);
          const isMultiple =
            isTable && isMultipleConnectionType(graphqlTypeFields[column.name.value], column);
          const directives = getDirectives(column.directives);
          return !isTable || (!isMultiple && directives.select);
        }
        case 'InlineFragment': {
          return true;
        }
        default: {
          console.warn(`[createDetailComponent] kind [${column.kind}] not supported`);
          return false;
        }
      }
    }).length;

    if (countFields > 0) {
      DetailComponent = nameComponent(
        `Detail.${graphqlType}`,
        createSelectionSetComponent(
          fields,
          graphqlTypeFields,
          document,
          schema,
          intlPrefix,
          createDetailInputComponent,
        ),
      );
    }

    tabs = (
      fields.filter((column) => {
        switch (column.kind) {
          case 'Field': {
            const isTable = isConnectionType(graphqlTypeFields[column.name.value].type);
            const isMultiple =
              isTable && isMultipleConnectionType(graphqlTypeFields[column.name.value], column);
            const directives = getDirectives(column.directives);
            return isTable && (isMultiple || !directives.select);
          }
          case 'InlineFragment': {
            return false;
          }
          default: {
            console.warn(`[createDetailComponent] kind [${column.kind}] not supported`);
            return false;
          }
        }
      }) as readonly FieldNode[]
    ).map((column) => {
      const graphqlField = graphqlTypeFields[column.name.value];
      const name = (graphqlField.type as GraphQLObjectType).name || graphqlField.name;

      const directives = getDirectives(column.directives);
      const TableComponent = directives.select
        ? createMassSelectComponent(graphqlField, column, document, schema)
        : createTableComponent(graphqlField, column, document, schema);
      return {
        name,
        Component: ({ data, errors, path, variables, loading }) => {
          const name = getFieldAliasOrName(column);
          const subPath = [...path, name];
          return (
            <TableComponent
              data={data?.[name]}
              errors={filterErrors(errors, subPath)}
              path={subPath}
              variables={variables}
              loading={loading}
              contextData={data}
            />
          );
        },
      };
    });
  }

  if (isListType(graphqlType)) {
    DetailComponent = nameComponent(
      `DetailList`,
      ({ data, loading, variables, path, errors }: DataProps) => (
        <>
          {(data as any[])?.map((item, idx) => {
            const subPath = [...path, idx];
            return (
              <DetailComponent
                key={idx}
                data={item}
                variables={variables}
                loading={loading}
                path={subPath}
                errors={filterErrors(errors, subPath)}
              />
            );
          })}
        </>
      ),
    );
  }

  let historyRoute = '';
  const directives = getDirectives(field?.directives);
  if (directives.history) {
    historyRoute = directives.history.route;
  }

  const actionDirectives = directives.action;
  const useActionDirectives: UseActionDirective[] = [];
  if (actionDirectives) {
    for (const directive of actionDirectives) {
      const useActionDirective = createUseActionDirective(document, schema, directive, idName);
      if (useActionDirective) {
        useActionDirectives.push(useActionDirective);
      }
    }
  }

  const DetailFormComponent = nameComponent(
    'DetailForm',
    ({ data, errors, path, variables, loading }: DataProps) => {
      const showHistoryLink = !!historyRoute;
      const navigateToHistory = useLink(loading ? '' : historyRoute, data, undefined, 'push', true);
      const [changedForm, setChangedForm] = useState<boolean>(false);

      const [value, setValue] = useReducer(
        (state: any, action: any) => {
          const newState = { ...state, ...action };
          console.debug('[DetailForm] new state', newState);
          return newState;
        },
        data,
        initState,
      );

      useEffect(() => {
        if (!loading) {
          const state = initState(data);
          setValue(state);
        }
      }, [loading]);

      const [callUpdateMutation, updateMutationStatus] = useMutationAction(
        document,
        schema,
        'update',
        value,
      );
      const [callDeleteMutation, deleteMutationStatus] = useMutationAction(
        document,
        schema,
        'delete',
        value,
      );

      const actionDirective = useActionDirectives.map((useActionDirective) =>
        useActionDirective({ variables, data: value }),
      );

      const handleChange = (state: unknown) => {
        setValue(state);
        setChangedForm(true);
      };

      const handleSubmit = () => {
        callUpdateMutation!({});
        setChangedForm(false);
      };

      const success = useMemo(
        () =>
          updateMutationStatus.called &&
          !updateMutationStatus.loading &&
          !updateMutationStatus.error &&
          !changedForm,
        [updateMutationStatus, changedForm],
      );

      const derivedErrors = useMemo(() => {
        return [...errors, ...(updateMutationStatus.error?.graphQLErrors ?? [])];
      }, [errors, updateMutationStatus]);

      const queryErrors = errors.filter(ownErrors(path));

      if (value === null) {
        return (
          <WaitingAlert>
            <FormattedMessage {...msg.waiting} />
          </WaitingAlert>
        );
      }

      return (
        <Form onSubmit={handleSubmit}>
          <div className='sg-u-vs-2'>
            <DetailComponent
              data={value}
              path={path}
              errors={derivedErrors}
              variables={variables}
              loading={loading}
              onChange={handleChange}
            />
            {queryErrors.length > 0 &&
              queryErrors.map((error, idx) => (
                <DangerAlert key={idx}>
                  <GraphQLQueryError error={error} />
                </DangerAlert>
              ))}
            {updateMutationStatus.error && <QueryError error={updateMutationStatus.error} />}
            {deleteMutationStatus.error && <QueryError error={deleteMutationStatus.error} />}
            {actionDirective.map(
              ([actionStatus], index) =>
                actionStatus.error && <QueryError error={actionStatus.error} key={index} />,
            )}
            {success && (
              <SuccessAlert>
                <FormattedMessage {...msg.success} />
              </SuccessAlert>
            )}
            <div className='sg-a-mt-2'>
              <BtnBar>
                {callUpdateMutation && (
                  <CtaBtn
                    type='submit'
                    isDisabled={deleteMutationStatus.loading}
                    queryState={updateMutationStatus.loading ? 'waiting' : undefined}
                    testId='tw-detailForm--update'
                  >
                    <FormattedMessage {...msg.formUpdate} />
                  </CtaBtn>
                )}
                {showHistoryLink && (
                  <SecondaryLink {...navigateToHistory}>
                    <FormattedMessage {...msg.formHistory} />
                  </SecondaryLink>
                )}
                {actionDirective.map(([_, buttonProps], index) => (
                  <SecondaryBtn
                    key={index}
                    {...buttonProps}
                    icon={buttonProps.icon && { element: buttonProps.icon }}
                  >
                    {buttonProps.tooltip}
                  </SecondaryBtn>
                ))}
                {callDeleteMutation && (
                  <SecondaryBtn
                    isDanger
                    isDisabled={updateMutationStatus.loading}
                    queryState={deleteMutationStatus.loading ? 'waiting' : undefined}
                    onClick={callDeleteMutation}
                    testId='tw-detailForm--delete'
                  >
                    <FormattedMessage {...msg.formDelete} />
                  </SecondaryBtn>
                )}
              </BtnBar>
            </div>
          </div>
        </Form>
      );
    },
  );

  const operationName = getOperationNameFromDocument(document);

  const PageTitleComponent: React.FC<{ data: any }> = nameComponent('PageTitle', ({ data }) => {
    const formatMessage = useMessage();
    return (
      <div className='sg-a-p-s sg-a-l-0 sg-a-mb-2'>
        <PageTitle>
          {formatMessage.fallback(
            [
              `${graphqlType.name}.page.title`,
              `${graphqlType.name}.${operationName}.page.title`,
              `${operationName}.page.title`,
              graphqlType.name,
            ],
            data ?? { _id: '' },
          ) ?? graphqlType.name}
        </PageTitle>
      </div>
    );
  });

  if (countFields > 0) {
    tabs.unshift({ name: 'detail', Component: DetailFormComponent });
  }

  if (tabs.length === 1) {
    return nameComponent('NoTabDetailForm', ({ data, errors, path, variables, loading }) => {
      const DetailComponent = tabs[0].Component;

      return (
        <DataLayoutSpacing>
          <PageTitleComponent data={data} />
          <DetailComponent
            data={data}
            path={path}
            errors={errors}
            variables={variables}
            loading={loading}
          />
        </DataLayoutSpacing>
      );
    });
  }

  return nameComponent('DetailForm', ({ data, errors, path, variables, loading }) => {
    const formatMessage = useMessage();
    const location = useLocation();
    const dispatch = useDispatch();

    const tabKey = `${location.route?.id ?? ''}_tab`;
    const urlTabId = location.hashParams[tabKey] ?? (location.params[tabKey] as string);
    const [currentTabId, setTabId] = useState<string | undefined>(urlTabId);
    const handleTabChange = useCallback(
      (_, eventData) => {
        const tabId = eventData.value;
        setTabId(tabId);
        dispatch(
          actionUpdateHashParams({
            [tabKey]: tabId,
          }),
        );
      },
      [dispatch, tabKey],
    );

    return (
      <DataLayoutSpacing>
        <PageTitleComponent data={data} />
        <Tabs value={currentTabId} onChange={handleTabChange}>
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              id={tab.name}
              label={
                formatMessage.fallback([
                  `${tab.name}.table.title`,
                  `${tab.name}.${operationName}.table.title`,
                  tab.name,
                ]) ?? tab.name
              }
            >
              <tab.Component
                data={data}
                path={path}
                errors={errors}
                variables={variables}
                loading={loading}
              />
            </Tab>
          ))}
        </Tabs>
      </DataLayoutSpacing>
    );
  });
}

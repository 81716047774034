import type {
  DirectiveNode,
  DocumentNode,
  FieldNode,
  GraphQLField,
  GraphQLObjectType,
  GraphQLSchema,
} from 'graphql';
import { getNamedType } from 'graphql';

import '../graphqlNodes';
import { isConnectionType } from '../utils/getConnectionInfo';
import { getDirectives } from '../utils/getDirectives';
import type { DataComponent } from '../types';

import { createDetailFormComponent } from './detailComponents/createDetailFormComponent';
import { createHistoryTableComponent } from './tableComponents/createHistoryTableComponent';
import { createTableComponent } from './tableComponents/createTableComponent';

export function createQueryComponent(
  graphqlField: GraphQLField<any, any, any>,
  directivesFromSchema: readonly DirectiveNode[],
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
): DataComponent {
  const graphqlType = graphqlField.type;
  const namedType = getNamedType(graphqlType);

  const directives = getDirectives([...directivesFromSchema, ...(field.directives ?? [])]);

  if (directives.historyTable) {
    return createHistoryTableComponent(graphqlField, field, document, schema);
  }

  if (isConnectionType(namedType)) {
    return createTableComponent(graphqlField, field, document, schema);
  }

  return createDetailFormComponent(graphqlField, field, document, schema);
}

import { useContext, useMemo, useReducer, useState } from 'react';
import type {
  DocumentNode,
  GraphQLFieldMap,
  GraphQLNamedType,
  GraphQLSchema,
  OperationDefinitionNode,
  ValueNode,
  VariableDefinitionNode,
  VariableNode,
} from 'graphql';
import { Kind, getNamedType, isInputObjectType } from 'graphql';

import { FormattedMessage } from 'react-intl';

import { useMessage } from '@tmapy/intl';
import { BtnBar, Form, CtaBtn, SecondaryBtn, DangerAlert, SuccessAlert } from '@tmapy/style-guide';

import type { DataComponent, DataProps } from '../../types';
import { msg } from '../../messages';
import { createFilterForDuplicatePasswords } from '../../utils/createFilterForDuplicatePasswords';
import { hiddenFieldsFilter } from '../../utils/getDirectives';
import { getDirectivesFromDescription } from '../../utils/getDirectivesFromDescription';
import { getScalarValue, isScalarValue } from '../../utils/isScalarValue';
import { nameComponent } from '../../utils/nameComponent';
import { formatVariables } from '../../utils/formatVariables';
import {
  GraphQLQueryError,
  QueryError,
  createErrorFilterForVariable,
} from '../../components/QueryError';
import { PageTitle } from '../../components/PageTitle';
import { Section } from '../../components/Section';
import { DataLayoutDialogContext } from '../../components/DataLayoutDialog';
import { DataLayoutSpacing } from '../../components/DataLayoutSpacing';

import { createVariableInputComponent } from './createVariableInputComponent';
import { useMutationAction } from '../useMutationAction';

const getFields = (
  document: DocumentNode,
  schema: GraphQLSchema,
  components: { Component: DataComponent; variableDefinition: VariableDefinitionNode }[],
  defaultValues: Record<string, any>,
  graphqlType?: GraphQLNamedType,
  variableDefinitions?: VariableDefinitionNode[],
  argument?: ValueNode,
) => {
  if (isInputObjectType(graphqlType)) {
    const namedType = getNamedType(graphqlType);
    const intlPrefix = namedType.name;

    const argumentNodes = argument?.kind === Kind.OBJECT ? argument.fields : [];

    const graphqlTypeFields = graphqlType.getFields();
    for (const graphqlTypeFieldName in graphqlTypeFields) {
      const namedType = getNamedType(graphqlTypeFields[graphqlTypeFieldName].type);
      const argumentNode = argumentNodes?.find(
        (argument) => argument.name?.value === graphqlTypeFieldName,
      );

      if (isInputObjectType(namedType)) {
        const argument = argumentNode?.value;
        getFields(
          document,
          schema,
          components,
          defaultValues,
          namedType,
          variableDefinitions,
          argument,
        );
      } else {
        const variableDefinition = variableDefinitions
          ?.filter(createFilterForDuplicatePasswords(graphqlTypeFields))
          .find(
            (variableDefinition) =>
              variableDefinition.variable.name.value ===
              (argumentNode?.value as VariableNode)?.name?.value,
          );
        if (
          !variableDefinition ||
          components.some((component) => component.variableDefinition === variableDefinition)
        ) {
          continue;
        }

        const description = graphqlTypeFields[graphqlTypeFieldName].description;
        const directivesFromSchema = getDirectivesFromDescription(description);

        components.push({
          Component: createVariableInputComponent(
            namedType,
            directivesFromSchema,
            variableDefinition,
            document,
            schema,
            intlPrefix,
          ),
          variableDefinition,
        });
        let defaultValue = graphqlTypeFields[graphqlTypeFieldName].defaultValue;
        if (isScalarValue(variableDefinition.defaultValue)) {
          defaultValue = getScalarValue(variableDefinition.defaultValue) ?? defaultValue;
        }
        defaultValues[graphqlTypeFieldName] = defaultValue;
      }
    }
  }
  return { components, defaultValues };
};

export const createNewFormComponent = (
  mutation: OperationDefinitionNode,
  types: GraphQLFieldMap<any, any>,
  document: DocumentNode,
  schema: GraphQLSchema,
) => {
  let selectionNode = mutation.selectionSet.selections[0];
  if (!selectionNode) return () => null;

  const variableDefinitions = mutation.variableDefinitions?.filter(hiddenFieldsFilter);
  const hiddenVariables = mutation.variableDefinitions?.filter(
    (variable) => !hiddenFieldsFilter(variable),
  );

  while (selectionNode.kind === Kind.INLINE_FRAGMENT) {
    selectionNode = selectionNode.selectionSet.selections[0];
  }
  if (selectionNode.kind !== Kind.FIELD) {
    return () => null;
  }
  const mutationNameFromType = selectionNode.name.value;

  const namedType = getNamedType(
    types[mutationNameFromType].args.find((arg) => arg.name === 'input')?.type,
  );
  const argument = selectionNode.arguments?.find((arg) => arg.name.value === 'input')?.value;

  const fields = getFields(document, schema, [], {}, namedType, variableDefinitions, argument);

  const mutationDocument: DocumentNode = {
    kind: Kind.DOCUMENT,
    definitions: [mutation],
  };

  const mutationName = mutation.name!.value;

  const Component = nameComponent('NewForm', ({ data, path, loading, variables }: DataProps) => {
    const formattedVariables = formatVariables(variables, mutation.variableDefinitions);

    const formatMessage = useMessage();
    const dataLayoutDialogContext = useContext(DataLayoutDialogContext);
    const [changedForm, setChangedForm] = useState<boolean>(false);

    const [value, handleChange] = useReducer(
      (state: any, action: any) => {
        setChangedForm(true);
        return { ...state, ...action };
      },
      { ...fields.defaultValues, ...data, ...formattedVariables },
    );

    const handleDialogClose = () => {
      (
        window.document.getElementById(dataLayoutDialogContext.dialogId) as HTMLDialogElement
      ).close();
    };

    const [callMutation, mutationStatus] = useMutationAction(
      mutationDocument,
      schema,
      mutationName,
      value,
      (dataLayoutDialogContext.isInsideDialog && handleDialogClose) || undefined,
    );

    const success = useMemo(
      () =>
        mutationStatus.called && !mutationStatus.loading && !mutationStatus.error && !changedForm,
      [mutationStatus, changedForm],
    );

    const handleSubmit = (e: React.FormEvent) => {
      e.stopPropagation();
      callMutation?.(value);
      setChangedForm(false);
    };

    return (
      <DataLayoutSpacing>
        <div className='sg-u-vs-2'>
          <PageTitle>
            {formatMessage.fallback([`${mutationName}.page.title`, mutationName]) ?? mutationName}
          </PageTitle>
          <Form onSubmit={handleSubmit}>
            <div className='sg-u-vs-2'>
              <Section>
                <div className='sg-u-box sg-a-p-2 tw-u-vs-3/2'>
                  {fields.components?.map(({ Component, variableDefinition }, index) => {
                    const variableName = variableDefinition.variable.name.value;
                    const data = value?.[variableName] ?? formattedVariables?.[variableName];
                    const componentErrors =
                      mutationStatus.error?.graphQLErrors.filter(
                        createErrorFilterForVariable(variableName),
                      ) ?? [];
                    return (
                      <Component
                        key={index}
                        data={data}
                        errors={componentErrors}
                        path={path}
                        isReadOnly={!!(typeof formattedVariables?.[variableName] !== 'undefined')}
                        loading={loading}
                        variables={formattedVariables}
                        onChange={handleChange}
                      />
                    );
                  })}
                </div>
              </Section>
              {hiddenVariables?.map((variableDefinition) => {
                const variableName = variableDefinition.variable.name.value;
                return (
                  mutationStatus.error?.graphQLErrors.filter(
                    createErrorFilterForVariable(variableName),
                  ) ?? []
                ).map((error, idx) => (
                  <DangerAlert key={idx}>
                    <GraphQLQueryError error={error} />
                  </DangerAlert>
                ));
              })}
              {mutationStatus.error && <QueryError error={mutationStatus.error} />}
              {success && (
                <SuccessAlert>
                  <FormattedMessage {...msg.success} />
                </SuccessAlert>
              )}

              <BtnBar>
                <CtaBtn type='submit' queryState={mutationStatus.loading ? 'waiting' : undefined}>
                  <FormattedMessage {...msg.formUpdate} />
                </CtaBtn>
                {dataLayoutDialogContext.isInsideDialog && (
                  <SecondaryBtn isDanger onClick={handleDialogClose}>
                    <FormattedMessage {...msg.formCancel} />
                  </SecondaryBtn>
                )}
              </BtnBar>
            </div>
          </Form>
        </div>
      </DataLayoutSpacing>
    );
  });

  (Component as any).selector = (data: any, variables: any) => {
    return variables;
  };

  return Component;
};

import React, { useCallback } from 'react';
import { MessageDescriptor } from 'react-intl';
import type { DocumentNode, GraphQLSchema } from 'graphql';
import type { MutationResult } from '@apollo/client';

import { useMessage } from '@tmapy/intl';
import { createComparator } from '@tmapy/utils';
import { SvgCopy, SvgTrash } from '@tmapy/style-guide';

import type { ActionDirectiveButtonProps, Variables } from '../../types';
import type { ActionDirective, _ActionType } from '../../utils/getDirectives';
import { isNamingID } from '../../utils/isNamingID';
import { getDirectives } from '../../utils/getDirectives';
import { getDirectivesFromDescription } from '../../utils/getDirectivesFromDescription';
import { msg } from '../../messages';
import { findMutation } from '../../utils/filters';
import { prepareClientDocument } from '../../visitors/prepareClientDocument';
import { FetchedIcon } from '../../components/FetchedIcon';

import { getDescriptionFromFields, useMutationAction } from '../useMutationAction';

export const ACTIONS: {
  type: _ActionType;
  isDanger: boolean;
  message: MessageDescriptor;
  icon: JSX.Element;
}[] = [
  {
    type: 'COPY',
    isDanger: false,
    message: msg.formCopy,
    icon: <SvgCopy />,
  },
  {
    type: 'DELETE',
    isDanger: true,
    message: msg.formDelete,
    icon: <SvgTrash />,
  },
];

type ActionDirectiveResult = [MutationResult, ActionDirectiveButtonProps];

type ActionDirectiveProps = {
  data: Record<string, any>;
  variables: Variables;
};

export type UseActionDirective = (props: ActionDirectiveProps) => ActionDirectiveResult;

type GetVariables = (variables: Variables) => Variables;

export const createUseActionDirective = (
  document: DocumentNode,
  schema: GraphQLSchema,
  actionDirective: ActionDirective,
  idName: string,
  getVariables: GetVariables = () => ({}),
): UseActionDirective | null => {
  const clientDocument = prepareClientDocument(document, schema, () => true);
  const mutation = findMutation(clientDocument, actionDirective.mutation);
  if (!mutation) {
    return null;
  }

  const variableDefinition = mutation.variableDefinitions
    ?.filter((variableDefinition) => {
      let type = variableDefinition.type;
      if (type.kind === 'ListType') {
        type = type.type;
      }
      if (type.kind === 'NonNullType') {
        type = type.type;
      }
      if (type.kind === 'NamedType') {
        return isNamingID(type.name.value);
      }
    })
    .sort(
      createComparator((variableDefinition) =>
        variableDefinition.variable.name.value === 'ids'
          ? -1
          : variableDefinition.variable.name.value === idName
          ? 0
          : 1,
      ),
    )[0];
  if (!variableDefinition) {
    throw new Error(`No identifier definition was found in '${actionDirective.mutation}'.`);
  }

  const isMassAction = variableDefinition.type.kind === 'ListType';
  const nameId = variableDefinition.variable.name.value;

  const action = ACTIONS.find((action) => action.type === actionDirective.type);
  const description = getDescriptionFromFields(mutation, schema.getMutationType()?.getFields());
  const directivesFromSchema = getDirectivesFromDescription(description);
  const directives = getDirectives(directivesFromSchema);
  const action2 = directives.action2;

  if (!action && !mutation.name?.value) {
    return null;
  }
  const isDanger = action2?.type === 'DANGER' || action?.isDanger;

  return ({ data, variables: sourceVariables }) => {
    const variables = { ...data, ...getVariables(sourceVariables), [nameId]: data?.[idName] };
    const formatMessage = useMessage();
    const fallback = isDanger ? msg.formDestroy : msg.formRun;
    const tooltip = formatMessage.fallback([mutation.name?.value, action?.message, fallback]) ?? '';

    const [callMutation, mutationStatus] = useMutationAction(
      document,
      schema,
      actionDirective.mutation,
      variables,
    );
    const handleBtnClick = useCallback(
      (e: React.SyntheticEvent) => {
        callMutation?.(variables);
        e.stopPropagation();
      },
      [callMutation, variables],
    );

    const name = action2?.icon ?? undefined;
    const icon = (name && FetchedIcon({ name })) || undefined;

    const buttonProps = {
      icon,
      tooltip,
      isDanger,
      queryState: mutationStatus.loading ? ('waiting' as const) : undefined,
      isMassAction,
      onClick: handleBtnClick,
    };

    return [mutationStatus, buttonProps];
  };
};

import { Icon, SvgSpinner } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';

import { useUploadFile } from './useUploadFile';

export const FileInput: DataComponent = ({ onChange }) => {
  const { handleChange, isUploading } = useUploadFile({ onChange });

  return (
    <div className='sg-a-d-f sg-a-ai-c sg-a-cg-1'>
      <input type='file' onChange={handleChange} />
      {isUploading && (
        <span className='sg-a-f-n'>
          <Icon element={<SvgSpinner />} />
        </span>
      )}
    </div>
  );
};

import { useCallback } from 'react';

import { DatePicker } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';

import { InlineViewComponentMap } from './InlineViewComponentMap';

export const DateInput: DataComponent = ({
  data,
  onChange,
  validate,
  isRequired,
  isReadOnly,
  loading,
  errors,
  path,
}) => {
  const handleChange = useCallback(
    (
      _,
      eventData: {
        name?: string;
        value: Date | null;
      },
    ) => {
      if (eventData.value) {
        try {
          const midday = new Date(eventData.value.getTime());
          midday.setHours(12);
          onChange?.(midday.toISOString().substring(0, 10));
        } catch (e) {
          onChange?.(undefined);
        }
      } else {
        onChange?.(null);
      }
    },
    [onChange],
  );

  if (isReadOnly) {
    return (
      <ReadOnlyFormField>
        <InlineViewComponentMap.Date
          loading={loading}
          data={data}
          errors={errors}
          path={path}
          variables={null}
          isReadOnly
          isRequired={isRequired}
        />
      </ReadOnlyFormField>
    );
  }

  return (
    <DatePicker
      value={data ? new Date(data) : null}
      minDay={validate?.min ? new Date(validate.min) : undefined}
      maxDay={validate?.max ? new Date(validate.max) : undefined}
      isRequired={isRequired}
      isInvalid={!!errors.length}
      onChange={handleChange}
    />
  );
};

import { useCallback, useMemo, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { DirectiveNode, DocumentNode, GraphQLSchema, OperationDefinitionNode } from 'graphql';
import { Kind, visit } from 'graphql';
import { useQuery } from '@apollo/client';

import { useLocation } from '@tmapy/router';
import { useMessage } from '@tmapy/intl';
import { CustomSelect, DangerBadge } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { getDirectives } from '../../utils/getDirectives';
import { removeDirectives } from '../../visitors/removeDirectives';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg } from '../../messages';

import { createUseTool } from '../tableComponents/createUseTool';

import {
  type Item,
  getTableData,
  renderSelectItem,
  renderSelectValue,
  mergeItems,
} from './getTableData';

export const createSelectComponent = (
  directiveNodes: readonly DirectiveNode[] | undefined,
  document: DocumentNode,
  schema: GraphQLSchema,
  isInline?: boolean,
  aliasOrName?: string,
): DataComponent => {
  const directives = getDirectives(directiveNodes);
  const selectDirective = directives.select;
  if (!selectDirective) {
    throw new Error('createSelectComponent: the select directive is missing.');
  }

  const selectQuery = document.definitions.find(
    (definition) =>
      definition.kind === 'OperationDefinition' && definition.name?.value === selectDirective.query,
  ) as OperationDefinitionNode;

  if (!selectQuery) {
    throw new Error(`createSelectComponent: ${selectDirective.query} is not in document.`);
  }

  const queryDocument: DocumentNode = {
    kind: Kind.DOCUMENT,
    definitions: [removeDirectives(selectQuery)],
  };

  let hasSearch: undefined | true;
  let hasIdFilter: undefined | true;
  visit(selectQuery, {
    Variable: (node) => {
      if (node.name.value === 'search') {
        hasSearch = true;
      }
      if (node.name.value === 'ids') {
        hasIdFilter = true;
      }
    },
  });

  const UseCreateTool = createUseTool(
    schema,
    'create',
    directives.create,
    directiveNodes,
    aliasOrName,
  );
  const UseDetailTool = createUseTool(
    schema,
    'detail',
    directives.detail,
    directiveNodes,
    aliasOrName,
  );

  if (isInline) {
    return ({ data }) => {
      const location = useLocation();
      const query = useQuery(queryDocument, {
        fetchPolicy: 'cache-and-network',
        variables: { ...location.params, ids: [data] },
      });
      const { items, totalCount } = useMemo(
        () => getTableData(query.data, selectQuery as OperationDefinitionNode),
        [query.data],
      );
      const value = useMemo(() => {
        if (items?.length) {
          // eslint-disable-next-line eqeqeq
          return data ? items?.find((item) => item.value == data) : null;
        }
      }, [items, data]);

      const hasMoreItems = totalCount > items.length;
      if (!hasIdFilter && hasMoreItems) {
        return (
          <DangerBadge>
            <FormattedMessage {...msg.dataError} />
          </DangerBadge>
        );
      }

      return <>{value?.label}</>;
    };
  }

  return ({ data, variables, onChange, isRequired, isReadOnly, errors }) => {
    const formatMessage = useMessage();

    const [search, setSearch] = useState('');
    const handleSearch = useCallback((_, { value }) => setSearch(value), []);

    const defaultItemsQuery =
      hasIdFilter &&
      useQuery(queryDocument, {
        fetchPolicy: 'cache-and-network',
        variables: data ? { ids: [data] } : { ids: [] },
      });
    const query = useQuery(queryDocument, {
      fetchPolicy: 'cache-and-network',
      variables: {
        ...variables,
        search: search || undefined,
      },
    });

    const [cachedItems, addCachedItems] = useReducer((state: Item[], action: Item[]) => {
      const newState = mergeItems(state, action);
      return newState;
    }, []);

    const defaultItems = useMemo(() => {
      const tableData = getTableData(defaultItemsQuery?.data, selectQuery);
      addCachedItems(tableData.items);
      return tableData.items;
    }, [defaultItemsQuery?.data]);

    const { items, totalCount } = useMemo(() => {
      const { items, totalCount } = getTableData(query.data, selectQuery);
      addCachedItems(items);
      return { items, totalCount };
    }, [query.data]);

    const itemsIncludingDefaultItems = useMemo(() => {
      const itemsIncludingDefaultItems = mergeItems(items, defaultItems);
      return itemsIncludingDefaultItems;
    }, [defaultItems, items]);

    const value = useMemo(() => {
      const value = cachedItems.find((item) => item.value == data) ?? null;
      return value;
    }, [cachedItems, data]);

    const handleChange = useCallback(
      (_, data) => {
        onChange?.(data.value?.value ?? null);
      },
      [onChange],
    );

    const id = data ?? '';
    const _id = id.substring(id.lastIndexOf('/') + 1);

    const { Tool: DetailTool } = UseDetailTool({ variables: { _id, id } });
    const { Tool: CreateTool } = UseCreateTool({ variables });

    const hasMoreItems = totalCount > items.length;
    if (!hasIdFilter && hasMoreItems) {
      return (
        <DangerBadge>
          <FormattedMessage {...msg.dataError} />
        </DangerBadge>
      );
    }

    if (isReadOnly) {
      return (
        <ReadOnlyFormField isLoading={query.loading}>
          {value?.label ? value.label : <span data-empty />}
        </ReadOnlyFormField>
      );
    }

    return (
      <>
        <CustomSelect
          value={value}
          items={itemsIncludingDefaultItems}
          hasClearBtn={!isRequired}
          renderValue={renderSelectValue}
          renderItem={renderSelectItem}
          isInvalid={!!errors.length}
          isLoading={query.loading}
          noItemsMessage={formatMessage(msg.noItems)}
          moreItemsMessage={hasMoreItems ? formatMessage(msg.moreItems, { totalCount }) : undefined}
          onChange={handleChange}
          onInputChange={hasSearch && handleSearch}
        >
          {!!_id && <DetailTool />}
          <CreateTool />
        </CustomSelect>
      </>
    );
  };
};

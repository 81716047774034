import { FormattedMessage } from 'react-intl.macro';

import type {
  DocumentNode,
  FieldNode,
  GraphQLFieldMap,
  GraphQLObjectType,
  GraphQLError,
  GraphQLSchema,
  GraphQLField,
} from 'graphql';
import { getNamedType } from 'graphql';
import { useEffect, useReducer } from 'react';

import { deepLooselyEquals } from '@tmapy/utils';
import { useMessage } from '@tmapy/intl';
import { Table } from '@tmapy/style-guide';

import type { GraphQLPath, PageInfo, PaginationVariableNames, Variables } from '../../types';
import { getDirectives, hiddenFieldsFilter } from '../../utils/getDirectives';
import { getDirectivesFromDescription } from '../../utils/getDirectivesFromDescription';
import { getFieldAliasOrName } from '../../utils/getFieldAliasOrName';
import { filterErrors, ownErrors } from '../../utils/filterErrors';
import { isNamingID } from '../../utils/isNamingID';
import { nameComponent } from '../../utils/nameComponent';
import { getFieldVariableNames } from '../../visitors/getFieldVariableNames';
import { getFieldsFromFragments } from '../../utils/getFieldsFromFragments';
import { getOperationNameFromDocument } from '../../utils/getOperationNameFromDocument';
import { createMutationFromSchema } from '../../documentFactories/createMutationFromSchema';
import { createDocumentNode } from '../../documentFactories/nodeFactories/createDocumentNode';
import { msg } from '../../messages';

import type { Selection, SelectionAll } from '../../components/TableView';
import { TableView } from '../../components/TableView';
import { TablePagination } from '../../components/TablePagination';
import { DataLayoutSpacing } from '../../components/DataLayoutSpacing';

import type { UseActionDirective } from './createUseActionDirective';
import { createUseActionDirective, ACTIONS } from './createUseActionDirective';
import { TABLE_ACTION_COLUMN_TYPES } from './createInlineActionComponent';
import { createTableFilterChipsComponent } from './createTableFilterChipsComponent';
import { createTableFilterComponent } from './createTableFilterComponent';
import { createTableHeaderComponent } from './createTableHeaderComponent';
import { createTableRowComponent } from './createTableRowComponent';
import { createUseTool } from './createUseTool';

type SelectionAction =
  | { type: 'UPDATE_DATA'; payload: { pageSize: number; totalCount: number; allIds: string[] } }
  | { type: 'SET_ALL_SELECTION'; payload: { value: SelectionAll } }
  | { type: 'SELECTION_CHANGE'; payload: { value: string } };

const selectionReducer = (state: Selection, action: SelectionAction): Selection => {
  switch (action.type) {
    case 'UPDATE_DATA': {
      if (
        state.pageSize === action.payload.pageSize &&
        state.totalCount === action.payload.totalCount &&
        (action.payload.pageSize >= action.payload.totalCount || state.all === 'ALL')
      ) {
        return { ...state, ...action.payload };
      }
      return { all: false, ids: [], ...action.payload };
    }
    case 'SET_ALL_SELECTION': {
      let all = action.payload.value;
      if (all === 'ALL' && state.totalCount <= state.pageSize) {
        all = true;
      }
      if (all === null && (!state.ids.length || state.ids.length === state.pageSize)) {
        return { ...state, all: true, ids: [] };
      }

      return { ...state, all };
    }
    case 'SELECTION_CHANGE': {
      let ids: string[];
      const value = action.payload.value;
      switch (state.all) {
        case 'ALL':
        case true:
          ids = state.allIds;
          break;
        case false:
          ids = [];
          break;
        case null:
          ids = state.ids;
          break;
      }
      ids = ids.includes(value) ? ids.filter((id) => id !== value) : [...ids, value];
      if (state.pageSize === ids.length) {
        return { ...state, all: true, ids: [] };
      } else {
        return { ...state, all: ids.length ? null : false, ids };
      }
    }
  }
};

type EdgeType<T> = {
  cursor?: string;
  node: T;
};

type ConnectionType<T> = {
  edges: EdgeType<T>[];
  totalCount?: number;
  pageInfo?: {
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
};

export type DataConnectionProps<T = any> = {
  data: ConnectionType<T>;
  errors: readonly GraphQLError[];
  path: GraphQLPath;
  variables: Variables;
  loading: boolean;
  contextData?: Record<string, any>;
  tools?: React.ReactNode[];
};

export type Column = {
  fieldNode: FieldNode;
  graphQLField: GraphQLField<any, any, any>;
};

export const classifyTableFields = (
  dataColumnsInTable: readonly FieldNode[],
  columnTypes: GraphQLFieldMap<any, any>,
): { idFields: Column[]; dataFields: Column[]; actionFields: Column[] } => {
  const idFields: Column[] = [];
  const dataFields: Column[] = [];
  const actionFields: Column[] = [];

  for (const fieldNode of dataColumnsInTable) {
    const graphQLField = columnTypes[fieldNode.name.value];
    const namedType = getNamedType(graphQLField.type);
    const directives = getDirectives(fieldNode.directives);
    if (!hiddenFieldsFilter(fieldNode)) {
      if (isNamingID(namedType.name)) {
        idFields.push({ fieldNode, graphQLField });
      }
    } else if (
      (TABLE_ACTION_COLUMN_TYPES.includes(namedType.name) && !directives.text) ||
      directives.url ||
      directives.ewkt
    ) {
      actionFields.push({ fieldNode, graphQLField });
    } else {
      dataFields.push({ fieldNode, graphQLField });
    }
  }
  return { idFields, dataFields, actionFields };
};

function getFieldFromSelectionSet(field: FieldNode, name: string): FieldNode {
  return field.selectionSet?.selections.find(
    (selection) => (selection as FieldNode).name.value === name,
  ) as FieldNode;
}

const NAMES_VARIABLES = ['order', 'first', 'last', 'before', 'after'];

export function createTableComponent(
  graphqlField: GraphQLField<any, any, any>,
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
): React.FC<DataConnectionProps> {
  const graphqlType = getNamedType(graphqlField.type) as GraphQLObjectType;

  const namesVariables = getFieldVariableNames(field, NAMES_VARIABLES);
  const filterVariables = getFieldVariableNames(field, NAMES_VARIABLES, true);

  const orderVariable = namesVariables['order'];
  const paginationVariables: PaginationVariableNames = {
    firstVariableName: namesVariables['first'],
    lastVariableName: namesVariables['last'],
    beforeVariableName: namesVariables['before'],
    afterVariableName: namesVariables['after'],
  };

  const edgeType = getNamedType(graphqlType.getFields().edges.type) as GraphQLObjectType;
  const nodeType = getNamedType(edgeType.getFields().node.type) as GraphQLObjectType;
  const columnTypes = nodeType.getFields();

  const edgesField = getFieldFromSelectionSet(field, 'edges');
  const nodeField = getFieldFromSelectionSet(edgesField, 'node');
  const queryColumns = nodeField.selectionSet!.selections!;

  const tableDirectives = getDirectives(field.directives);
  const rowDirectives = getDirectives(nodeField.directives);

  const tableContext = tableDirectives.decorate?.props?.context;

  const columns = getFieldsFromFragments(queryColumns);
  const { idFields, dataFields, actionFields } = classifyTableFields(columns, columnTypes);
  const idName = idFields[0] ? getFieldAliasOrName(idFields[0].fieldNode) : '_id';

  const TableFilter = createTableFilterComponent(
    document,
    schema,
    Object.values(filterVariables),
    Object.values(paginationVariables),
  );
  const TableFilterChips = createTableFilterChipsComponent(
    document,
    schema,
    Object.values(filterVariables),
  );
  const Pagination = nameComponent(
    'Pagination',
    (data: { defaultPageSize?: number; pageInfo?: PageInfo }) => {
      const { defaultPageSize, pageInfo } = data;

      return pageInfo ? (
        <TablePagination
          pageInfo={pageInfo}
          paginationVariables={paginationVariables}
          defaultPageSize={defaultPageSize}
        />
      ) : null;
    },
  );

  const actionDirectives = (rowDirectives?.action ?? []).sort((a, b) => {
    return (
      ACTIONS.findIndex((action) => action.type === a.type) -
      ACTIONS.findIndex((action) => action.type === b.type)
    );
  });
  const useActionDirectives: UseActionDirective[] = [];

  const filters = graphqlField.args.filter((arg) => !NAMES_VARIABLES.includes(arg.name));
  const mutations = schema.getMutationType()?.getFields();
  let hasMassSelection = false;
  for (const mutation in mutations) {
    const isIdenticalMutationInput = mutations[mutation].args.every((arg) => {
      const filter = filters.find((filter) => filter.name === arg.name);
      if (!filter && mutations[mutation].args.length > 1 && tableContext === 'MASS_SELECT') {
        return getNamedType(arg.type).name === 'NodeInput';
      }
      return deepLooselyEquals(getNamedType(arg.type), getNamedType(filter?.type));
    });
    const directiveNodes = getDirectivesFromDescription(mutations[mutation].description);
    const directives = getDirectives(directiveNodes);
    const query = directives.mass?.query;
    if (isIdenticalMutationInput || query === graphqlField.name) {
      const id = directives.mass?.id;
      const getVariables = id ? (variables: Variables) => ({ id: variables?.[id] }) : undefined;

      const definition = createMutationFromSchema(mutation, schema, mutations[mutation], []);
      const document = createDocumentNode([definition]);
      const useActionDirective = createUseActionDirective(
        document,
        schema,
        { mutation },
        idName,
        getVariables,
      );
      if (useActionDirective) {
        useActionDirectives.push(useActionDirective);
        hasMassSelection = true;
      }
    }
  }

  if (actionDirectives) {
    for (const directive of actionDirectives) {
      const useActionDirective = createUseActionDirective(document, schema, directive, idName);
      if (useActionDirective) {
        useActionDirectives.push(useActionDirective);
      }
    }
  }

  const TableHeader = createTableHeaderComponent(
    dataFields,
    actionFields,
    rowDirectives,
    nodeType.name,
    orderVariable,
    paginationVariables,
    useActionDirectives,
    idName,
    hasMassSelection,
  );
  const TableRow = createTableRowComponent(
    dataFields,
    actionFields,
    rowDirectives,
    document,
    schema,
    nodeType.name,
    useActionDirectives,
    idName,
    hasMassSelection,
  );

  const useTool = createUseTool(schema, 'create', tableDirectives.create, field.directives);

  const operationName = getOperationNameFromDocument(document);

  return nameComponent(
    `Table.${nodeType.name}`,
    ({ data, errors, path, loading, variables, contextData, tools = [] }: DataConnectionProps) => {
      const formatMessage = useMessage();
      const { Tool: CreateTool, upload } = useTool({ variables });
      const tableName =
        formatMessage.fallback(
          [
            `${graphqlType.name}.table.title`,
            `${graphqlType.name}.${operationName}.table.title`,
            graphqlType.name,
            field.name.value,
          ],
          contextData,
        ) ?? graphqlType.name;

      const totalCount = data?.totalCount || 0;
      const pageSize = data?.edges?.length || 0;

      const isLoading = upload?.isUploading || loading;

      const defaultSelectionState = { all: false, ids: [], allIds: [], totalCount, pageSize };
      const [selection, dispatchSelection] = useReducer(selectionReducer, defaultSelectionState);

      useEffect(() => {
        const allIds = data?.edges?.map((data) => data.node[idName]);
        dispatchSelection({ type: 'UPDATE_DATA', payload: { pageSize, totalCount, allIds } });
      }, [data, idName]);

      const handleSelectionChange = (value: string) => {
        dispatchSelection({ type: 'SELECTION_CHANGE', payload: { value } });
      };

      const handleCancelSelection = () => {
        dispatchSelection({ type: 'SET_ALL_SELECTION', payload: { value: false } });
      };

      const handleSelectingAll = () => {
        dispatchSelection({ type: 'SET_ALL_SELECTION', payload: { value: 'ALL' } });
      };

      const handleChangeAllSelection = (value: boolean | null) => {
        dispatchSelection({ type: 'SET_ALL_SELECTION', payload: { value } });
      };

      tools.push(
        <CreateTool isDisabled={isLoading}>
          <FormattedMessage {...msg.formAdd} />
        </CreateTool>,
      );

      const tableView = (
        <TableView
          tableName={tableName}
          context={tableContext}
          totalCount={totalCount}
          pageSize={pageSize}
          selection={selection}
          onCancelSelection={handleCancelSelection}
          onSelectingAll={handleSelectingAll}
          isLoading={isLoading}
          variables={variables}
          FilterComponent={TableFilter}
          FilterChipsComponent={TableFilterChips}
          pagination={
            <Pagination
              pageInfo={data?.pageInfo as PageInfo | undefined}
              defaultPageSize={data?.edges?.length}
            />
          }
          tools={tools}
          onUpload={upload?.handleUpload}
          errors={errors?.filter(ownErrors(path)).concat(...(upload?.error?.graphQLErrors ?? []))}
        >
          <Table isClickable={!!rowDirectives?.detail} isHoverable={!!rowDirectives?.loadFeature}>
            <TableHeader
              selection={selection}
              onChangeAllSelection={handleChangeAllSelection}
              variables={variables}
            />
            <tbody>
              {data?.edges?.map((item, index) => {
                const subPath = [...path, 'edges', index, 'node'];
                return (
                  <TableRow
                    key={item.node?.[idName] ?? item.node?.id ?? index}
                    data={item.node}
                    errors={filterErrors(errors, subPath)}
                    path={subPath}
                    variables={variables}
                    loading={loading}
                    onSelectionChange={handleSelectionChange}
                    selection={selection}
                  />
                );
              })}
            </tbody>
          </Table>
        </TableView>
      );

      if (tableContext === 'PAGE' || tableContext === 'MASS_SELECT') {
        return <DataLayoutSpacing>{tableView}</DataLayoutSpacing>;
      }
      return tableView;
    },
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { GraphQLEnumType } from 'graphql';

import { useMessage } from '@tmapy/intl';
import { MultiSelect } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg } from '../../messages';

import { renderSelectValue, type Item } from './getTableData';

export const createMultiEnumComponent = (namedType: GraphQLEnumType): DataComponent => {
  const name = namedType.name;
  const namedTypeValues = namedType.getValues();

  return ({ data, errors, onChange, isReadOnly }) => {
    const formatMessage = useMessage();
    const getLabel = useCallback(
      (value: string) => formatMessage.fallback([`${name}.${value}`, value], { value }) ?? value,
      [formatMessage],
    );

    const values = useMemo(() => {
      return Array.isArray(data)
        ? data.map((item: any) => ({ value: item, label: getLabel(item) }))
        : data
        ? [{ value: data, label: getLabel(data) }]
        : [];
    }, [data]);

    const [, setSearch] = useState('');

    const items = useMemo(
      () =>
        namedTypeValues.map((namedTypeValue) => {
          const value = namedTypeValue.value;
          const label = getLabel(value);
          return { value, label };
        }),
      [namedTypeValues, getLabel],
    );

    const handleChange = (e: React.MouseEvent, data: { values: Item[] }) => {
      onChange?.(data.values.map((item: Item) => item.value));
    };

    if (isReadOnly) {
      return (
        <ReadOnlyFormField>
          {values.length ? (
            values.map((value, idx) => (
              <React.Fragment key={value.value}>
                {idx > 0 && ' | '}
                {value.label}
              </React.Fragment>
            ))
          ) : (
            <span data-empty />
          )}
        </ReadOnlyFormField>
      );
    }

    return (
      <MultiSelect
        values={values}
        items={items}
        renderItem={renderSelectValue}
        renderValue={renderSelectValue}
        onChange={handleChange}
        isInvalid={!!errors.length}
        noItemsMessage={formatMessage(msg.noItems)}
        onInputChange={useCallback((_, { value }) => setSearch(value), [])}
      />
    );
  };
};

import { defineMessages } from 'react-intl.macro';

import type { GraphQLError } from 'graphql';
import { ReactNode, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useMessage } from '@tmapy/intl';
import { SvgFilter, SecondaryBtn, DangerAlert, UploadDropZone, CtaBtn } from '@tmapy/style-guide';

import './TableView.scss';

import type { Variables } from '../types';
import { ComponentContext } from '../utils/getDirectives';

import { PageTitle } from './PageTitle';
import { LoadingIndicator } from './LoadingIndicator';
import { GraphQLQueryError } from './QueryError';

export const msg = defineMessages({
  uploadDropZone: {
    id: 'sys.table.uploadDropZone',
    defaultMessage: 'Sem přetáhněte vaše soubory',
  },
  cancelSelection: {
    id: 'sys.table.cancelSelection',
    defaultMessage: 'Zrušit výběr',
  },
  select: {
    id: 'sys.table.select',
    defaultMessage: 'Vybrat',
  },
  selected: {
    id: 'sys.table.selected',
    defaultMessage: 'Vybráno',
  },
  onPage: {
    id: 'sys.table.onPage',
    defaultMessage: 'na stránce',
  },
  all: {
    id: 'sys.table.all',
    defaultMessage: `{totalCount, plural,
    =0 {}
    one {}
    few {všechny}
    =2 {# všechny}
    =3 {# všechny}
    =4 {# všechny}
    other {všech}
  }`,
  },
  totalCount: {
    id: 'sys.table.totalCount',
    defaultMessage: `{totalCount, plural,
      =0 {Žádný záznam}
      one {# záznam}
      few {# záznamy}
      =2 {# záznamy}
      =3 {# záznamy}
      =4 {# záznamy}
      other {# záznamů}
    }`,
  },
  selectionCount: {
    id: 'sys.table.selectionCount',
    defaultMessage: `
    {selectionSize, plural,
      =0 {Nevybrán žádný záznam }
      one {Vybrán # záznam }
      few {Vybrány # záznamy }
      =2 {Vybrány # záznamy }
      =3 {Vybrány # záznamy }
      =4 {Vybrány # záznamy }
      other {Vybráno # záznamů }
    }
    `,
  },
  totalCountWithSelection: {
    id: 'sys.table.totalCountWithSelection',
    defaultMessage: `
    {totalCount, plural,
      =0 {ze žádného záznamu}
      one {z # záznamu}
      few {ze # záznamů}
      =2 {ze # záznamů}
      =3 {ze # záznamů}
      =4 {ze # záznamů}
      other {z # záznamů}
    }`,
  },
});

type TableBoxProps = {
  showTable: boolean;
  pagination: ReactNode;
  errors?: readonly GraphQLError[];
};

const TableBox: React.FC<TableBoxProps> = ({ showTable, children, pagination }) => {
  return showTable ? (
    <div className='sg-u-box'>
      {children}
      {pagination}
    </div>
  ) : null;
};

export type SelectionAll = boolean | null | 'ALL';
export type Selection = {
  all: SelectionAll;
  ids: string[];
  allIds: string[];
  pageSize: number;
  totalCount: number;
};

type TableViewProps = {
  variables: Variables;
  tableName: string;
  context?: ComponentContext;
  totalCount?: number;
  pageSize: number;
  selection?: Selection;
  onSelectingAll?(): void;
  onCancelSelection?(): void;
  isLoading: boolean;
  tools?: ReactNode;
  onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  FilterComponent?: React.FC<{ variables: Variables }> | null;
  FilterChipsComponent?: React.ComponentType | null;
  pagination?: ReactNode;
  errors?: readonly GraphQLError[];
};

export const TableView: React.FC<TableViewProps> = ({
  variables,
  children,
  isLoading,
  FilterComponent,
  FilterChipsComponent,
  pagination,
  tableName,
  context,
  tools,
  onUpload,
  totalCount,
  pageSize,
  selection,
  onSelectingAll,
  onCancelSelection,
  errors,
}) => {
  const formatMessage = useMessage();

  const [showFilter, setFilterVisible] = useState(false);

  const handleFilterBtnClick = useCallback(() => {
    setFilterVisible((state) => !state);
  }, []);

  const handleDrop = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpload?.(e);
    e.target.value = '';
  }, []);

  return (
    <>
      <div className='sg-a-p-s sg-a-l-0 sg-u-vs-1'>
        <div
          className={classNames({
            'sg-a-d-f sg-a-fw-w sg-a-ai-b sg-a-g-1': true,
            'tw-tableView--head': context === 'PAGE' || context === 'MASS_SELECT',
          })}
        >
          <div className='sg-a-f-a tw-tableView--title'>
            <div className='sg-a-d-f sg-a-fw-w sg-a-ai-b sg-a-cg-1'>
              {context !== 'DETAIL' && (
                <PageTitle isSmall={context !== 'PAGE' && context !== 'MASS_SELECT'}>
                  {tableName}
                </PageTitle>
              )}
              {totalCount !== undefined && (
                <>
                  {onCancelSelection && selection?.all === 'ALL' && (
                    <>
                      <span className='sg-u-type-sml'>
                        <FormattedMessage {...msg.selected} />{' '}
                        <FormattedMessage {...msg.all} values={{ totalCount: totalCount }} />{' '}
                        <b className='sg-u-type-bold'>
                          <FormattedMessage
                            {...msg.totalCount}
                            values={{ totalCount: totalCount }}
                          />
                        </b>
                      </span>
                      <SecondaryBtn onClick={onCancelSelection} isDanger>
                        <FormattedMessage {...msg.cancelSelection} />
                      </SecondaryBtn>
                    </>
                  )}
                  {onSelectingAll && selection?.all === true && totalCount > pageSize && (
                    <>
                      <span className='sg-u-type-sml'>
                        <FormattedMessage {...msg.selected} />{' '}
                        <FormattedMessage {...msg.totalCount} values={{ totalCount: pageSize }} />{' '}
                        <FormattedMessage {...msg.onPage} />
                      </span>
                      <CtaBtn onClick={onSelectingAll}>
                        <FormattedMessage {...msg.select} />{' '}
                        <FormattedMessage {...msg.all} values={{ totalCount: totalCount }} />{' '}
                        <FormattedMessage {...msg.totalCount} values={{ totalCount: totalCount }} />
                      </CtaBtn>
                    </>
                  )}
                  {selection?.all === true && totalCount <= pageSize && (
                    <span className='sg-u-type-sml sg-u-c-body-darker'>
                      <FormattedMessage
                        {...msg.selectionCount}
                        values={{ selectionSize: totalCount }}
                      />{' '}
                      <FormattedMessage
                        {...msg.totalCountWithSelection}
                        values={{ totalCount: totalCount }}
                      />
                    </span>
                  )}
                  {selection?.all === null && (
                    <span className='sg-u-type-sml sg-u-c-body-darker'>
                      <FormattedMessage
                        {...msg.selectionCount}
                        values={{ selectionSize: selection.ids.length }}
                      />{' '}
                      <FormattedMessage
                        {...msg.totalCountWithSelection}
                        values={{ totalCount: totalCount }}
                      />
                    </span>
                  )}
                  {(selection?.all === false || !selection) && (
                    <span className='sg-u-type-sml sg-u-c-body-darker'>
                      <FormattedMessage {...msg.totalCount} values={{ totalCount: totalCount }} />
                    </span>
                  )}
                </>
              )}
            </div>
            <LoadingIndicator isLoading={isLoading} />
          </div>
          {tools}
          {FilterComponent && (
            <SecondaryBtn
              isActive={showFilter}
              icon={{ element: <SvgFilter /> }}
              onClick={handleFilterBtnClick}
              testId='tw-tableView--showFilter'
            />
          )}
        </div>
        {FilterChipsComponent && <FilterChipsComponent />}
      </div>
      <div
        className={classNames({
          'tw-tableView--body sg-a-d-f sg-a-ai-fs': true,
          'sg-a-mt-2': context !== undefined,
          'sg-a-mt-1': context === undefined,
        })}
      >
        <div className='sg-a-f-a sg-u-vs-2'>
          {onUpload ? (
            <UploadDropZone
              name='files'
              isShown={!totalCount && !isLoading}
              label={formatMessage(msg.uploadDropZone)}
              onDrop={handleDrop}
            >
              <TableBox showTable={!!totalCount} pagination={pagination}>
                {children}
              </TableBox>
            </UploadDropZone>
          ) : (
            <TableBox showTable={!!totalCount} pagination={pagination}>
              {children}
            </TableBox>
          )}
          {errors?.map((error, idx) => (
            <DangerAlert key={idx}>
              <GraphQLQueryError error={error} />
            </DangerAlert>
          ))}
        </div>
        {FilterComponent && showFilter && (
          <div className='tw-tableView--filter sg-a-f-n'>
            <input type='checkbox' autoFocus className='tw-tableView--autofocusElement' />
            <FilterComponent variables={variables} />
          </div>
        )}
      </div>
    </>
  );
};

import { useState } from 'react';

import { useMessage } from '@tmapy/intl';
import { SvgEye, Input, InputProps, SvgEyeOff, TertiaryBtn } from '@tmapy/style-guide';

import { msg } from '../../messages';

export const PasswordInput = ({ value, isRequired, isInvalid, onChange, testId }: InputProps) => {
  const formatMessage = useMessage();
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const handleEyeBtnClick = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  return (
    <Input
      type={isPasswordVisible ? 'text' : 'password'}
      value={value}
      isRequired={isRequired}
      isInvalid={isInvalid}
      onChange={onChange}
      testId={testId}
    >
      {value && (
        <TertiaryBtn
          icon={{ element: isPasswordVisible ? <SvgEyeOff /> : <SvgEye /> }}
          tooltip={
            isPasswordVisible ? formatMessage(msg.hidePassword) : formatMessage(msg.showPassword)
          }
          onClick={handleEyeBtnClick}
        />
      )}
    </Input>
  );
};

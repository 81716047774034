import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { DocumentNode, OperationDefinitionNode, DirectiveNode } from 'graphql';
import { Kind, visit } from 'graphql';

import { useQuery } from '@apollo/client';

import { useLocation } from '@tmapy/router';
import { DangerBadge, MultiSelect } from '@tmapy/style-guide';
import { useMessage } from '@tmapy/intl';

import type { DataComponent } from '../../types';
import { getDirectives } from '../../utils/getDirectives';
import { removeDirectives } from '../../visitors/removeDirectives';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg } from '../../messages';

import type { Item } from './getTableData';
import {
  compareItems,
  getTableData,
  mergeItems,
  renderSelectItem,
  renderSelectValue,
} from './getTableData';

export const createMultiSelectComponent = (
  directiveNodes: readonly DirectiveNode[] | undefined,
  document: DocumentNode,
  isInline?: boolean,
): DataComponent => {
  const directives = getDirectives(directiveNodes);
  const selectDirective = directives.select;
  if (!selectDirective) {
    throw new Error('createSelectComponent: the select directive is missing.');
  }

  const selectQuery = document.definitions.find(
    (definition) =>
      definition.kind === 'OperationDefinition' && definition.name?.value === selectDirective.query,
  ) as OperationDefinitionNode;

  if (!selectQuery) {
    throw new Error(`createSelectComponent: ${selectDirective.query} is not in document.`);
  }

  const queryDocument: DocumentNode = {
    kind: Kind.DOCUMENT,
    definitions: [removeDirectives(selectQuery)],
  };

  let hasSearch: undefined | true;
  let hasIdFilter: undefined | true;
  visit(selectQuery, {
    Variable: (node) => {
      if (node.name.value === 'search') {
        hasSearch = true;
      }
      if (node.name.value === 'ids') {
        hasIdFilter = true;
      }
    },
  });

  if (isInline) {
    return ({ data }) => {
      const location = useLocation();
      const query = useQuery(queryDocument, {
        fetchPolicy: 'cache-and-network',
        variables: { ...location.params, ids: data },
      });
      const { items, totalCount } = useMemo(
        () => getTableData(query.data, selectQuery as OperationDefinitionNode),
        [query],
      );
      const values = useMemo(() => {
        if (items?.length) {
          return data ? items?.filter((item) => data.includes(item.value)) : [];
        }
      }, [items, data]);

      const hasMoreItems = totalCount > items.length;
      if (!hasIdFilter && hasMoreItems) {
        return (
          <DangerBadge>
            <FormattedMessage {...msg.dataError} />
          </DangerBadge>
        );
      }

      return (
        <ReadOnlyFormField isLoading={query.loading}>
          {values?.map((value, idx) => (
            <React.Fragment key={value.value}>
              {idx > 0 && ' | '}
              {value.label}
            </React.Fragment>
          ))}
        </ReadOnlyFormField>
      );
    };
  }

  return ({ data, variables, onChange, isReadOnly, errors }) => {
    const formatMessage = useMessage();

    const [search, setSearch] = useState('');
    const handleSearch = useCallback((_, { value }) => setSearch(value), []);

    const defaultItemsQuery =
      hasIdFilter &&
      useQuery(queryDocument, {
        fetchPolicy: 'cache-and-network',
        variables: { ids: data },
      });
    const query = useQuery(queryDocument, {
      fetchPolicy: 'cache-and-network',
      variables: {
        ...variables,
        search: search || undefined,
      },
    });

    const [cachedItems, addCachedItems] = useReducer((state: Item[], action: Item[]) => {
      const newState = mergeItems(state, action);
      return newState;
    }, []);

    const defaultItems = useMemo(() => {
      const tableData = getTableData(defaultItemsQuery?.data, selectQuery);
      addCachedItems(tableData.items);
      return tableData.items;
    }, [defaultItemsQuery?.data]);

    const { items, totalCount } = useMemo(() => {
      const { items, totalCount } = getTableData(query.data, selectQuery);
      addCachedItems(items);
      return { items, totalCount };
    }, [query.data]);

    const itemsIncludingDefaultItems = useMemo(() => {
      const itemsIncludingDefaultItems = mergeItems(items, defaultItems);
      return itemsIncludingDefaultItems;
    }, [defaultItems, items]);

    const values = useMemo(() => {
      const values = cachedItems.filter((item) => data?.includes(item.value)) ?? [];
      return values;
    }, [cachedItems, data]);

    // TODO: opravit ve SG nebo s onChange zaroven zavolat setSearch('')
    const handleChange = useCallback(
      (_, data) => {
        onChange?.(data.values.map((item: Item) => item.value));
      },
      [onChange],
    );

    const hasMoreItems = totalCount > items.length;
    if (!hasIdFilter && hasMoreItems) {
      return (
        <DangerBadge>
          <FormattedMessage {...msg.dataError} />
        </DangerBadge>
      );
    }

    if (isReadOnly) {
      return (
        <ReadOnlyFormField isLoading={query.loading}>
          {values.length ? (
            values.map((value, idx) => (
              <React.Fragment key={value.value}>
                {idx > 0 && ' | '}
                {value.label}
              </React.Fragment>
            ))
          ) : (
            <span data-empty />
          )}
        </ReadOnlyFormField>
      );
    }

    return (
      <MultiSelect
        values={values}
        items={itemsIncludingDefaultItems}
        isDisabled={isReadOnly}
        isInvalid={!!errors.length}
        noItemsMessage={formatMessage(msg.noItems)}
        moreItemsMessage={hasMoreItems ? formatMessage(msg.moreItems, { totalCount }) : undefined}
        isLoading={query.loading}
        onChange={handleChange}
        renderItem={renderSelectItem}
        renderValue={renderSelectValue}
        compareItems={compareItems}
        onInputChange={hasSearch ? handleSearch : () => undefined}
      />
    );
  };
};
